import React from "react"
import {
  Grid,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  List,
} from "@material-ui/core"
import { Link } from "gatsby"
import NavigateNext from "@material-ui/icons/NavigateNext"
import LoadMoreButton from "./load-more-button"
import * as LayoutUtils from "../helpers/layout-utils"
import { ArtistLabelList } from "../helpers/discogs-types"

interface ItemsListOpts {
  type: string
  items: ArtistLabelList
  nextPage: string
  loadingPage: boolean
  loadMoreHandler: () => Promise<void>
}

const ItemsList = (props: ItemsListOpts) => {
  const classes = LayoutUtils.useStyles()

  let first = true
  let artists
  if (props.items) {
    artists = props.items.map((artist, idx) => {
      let divider = null
      if (!first) {
        divider = <Divider variant="fullWidth" component="li" />
      } else {
        first = false
      }

      const itemUrl = "/collection/" + props.type + "/" + artist.id

      return (
        <div key={idx}>
          {divider}
          <ListItem
            button
            component={Link}
            to={itemUrl}
            state={{ name: artist.name }}
          >
            <ListItemText primary={artist.name} />
            <ListItemAvatar>
              <Avatar>{artist.count}</Avatar>
            </ListItemAvatar>
            <ListItemIcon>
              <NavigateNext />
            </ListItemIcon>
          </ListItem>
        </div>
      )
    })
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <List>{artists}</List>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.loadBtnRow}>
          <LoadMoreButton
            hasNextPage={props.nextPage.length > 0}
            isLoading={props.loadingPage}
            clickHandler={props.loadMoreHandler}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ItemsList
