import React, { useState, useCallback } from "react"
import * as DiscogsUtils from "../helpers/discogs-utils"
import * as Cache from "../helpers/cache"
import ItemsList from "../components/items-list"
import { ArtistLabelList } from "../helpers/discogs-types"
import { graphql, useStaticQuery } from "gatsby"
import Page from "../components/page"
import { Helmet } from "react-helmet"

const getArtists = async (cursor: string) => {
  return DiscogsUtils.getArtistItems(cursor)
}

const setArtistsCache = (artists: ArtistLabelList) => {
  const CACHE_KEY = "artists"

  Cache.setItem(CACHE_KEY, JSON.stringify(artists))
}

const getArtistsCache = (): ArtistLabelList | null => {
  const CACHE_KEY = "artists"

  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const CACHE_KEY_CURSOR = "artists-cursor"

export default function Artists() {
  const buildData = useStaticQuery(graphql`
    query {
      artists {
        nextPage
        items {
          id
          name
          count
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let initArtists = buildData.artists.items
  let initNextPage = buildData.artists.nextPage

  const [artists, setArtists] = useState<ArtistLabelList>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheArtists = getArtistsCache()
      if (cacheArtists !== null) {
        return cacheArtists
      }
    }
    return initArtists
  })
  const [nextPage, setNextPage] = useState<string>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheCursor = Cache.getItem(CACHE_KEY_CURSOR)
      if (cacheCursor !== null) {
        return cacheCursor
      }
    }
    return initNextPage
  })
  const [loadingPage, setLoadingPage] = useState<boolean>(false)

  const siteTitle = buildData.site.siteMetadata.title

  const loadMoreHandler = useCallback(async () => {
    setLoadingPage(true)
    const response = await getArtists(nextPage)
    setArtists(prev => {
      const items = [...prev, ...response.items]
      setArtistsCache(items)
      return items
    })
    setNextPage(response.cursor)
    Cache.setItem(CACHE_KEY_CURSOR, response.cursor)
    setLoadingPage(false)
  }, [nextPage])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${siteTitle} - Artists`}</title>
        <meta
          name="description"
          content="A list of the artists and bands in my record collection"
        />
      </Helmet>
      <Page navTitle="Artists">
        <ItemsList
          type="artists"
          items={artists}
          nextPage={nextPage}
          loadingPage={loadingPage}
          loadMoreHandler={loadMoreHandler}
        />
      </Page>
    </React.Fragment>
  )
}
